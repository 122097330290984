import { DispatchProgramFilters } from '../../../../app/models/DispatchProgram';
import { DispatchProgramHistoryDataForm, DispatchProgramHistoryFilters } from '../../../../app/models/DispatchProgramHistory';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import DispatchProgramHistoryFilter from './DispatchProgramHistoryFilter';

interface Props {
    dispatchProgramHistories: DispatchProgramHistoryDataForm[];  
    dispatchProgramId: number;
    totalRows: number;
    loading?: boolean;
    filter: DispatchProgramHistoryFilters;
    setFilter: (filter: DispatchProgramHistoryFilters) => void;
    paginationRowsPerPageOptions?: any[];
    callbackSuccess: () => void;
}

const DispatchProgramHistoryDataTable = ({
    dispatchProgramHistories,
    dispatchProgramId,
    totalRows,
    loading = false,
    filter,
    setFilter,
    callbackSuccess
}: Props) => {
    const columns = [
        {
            name: 'ID',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">{row.id}</span>
            )
        },

        {
            name: 'Descripcion',
            selector: (row: any) => row.description,
            sortable: true,
            sortField: 'description',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">{row.description}</span>
            )
        },

        {
            name: 'Razón del Cambio',
            selector: (row: any) => row.reason,
            sortable: true,
            sortField: 'reason',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">{row.reason}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row.status,
            sortable: true,
            sortField: 'status',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">{row.status}</span>
            )
        },
        {
            name: 'Creado por',
            selector: (row: any) => row.created_user?.username,
            sortable: true,
            sortField: 'created_user',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                    __html: row.created_user ? String(row.created_user) : ''               

                }}
                />
            </span>
            )
        },
        {
            name: 'Fecha de Cambio',
            selector: (row: any) => row.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: DispatchProgramHistoryDataForm) => (
                <span className="">{momentParseDate(row.created_at)}</span>
            )
        },
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <DispatchProgramHistoryFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={dispatchProgramHistories}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default DispatchProgramHistoryDataTable;

