  import React, { useContext, useEffect, useState } from 'react';

import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import { TaxDocumentDataForm, TaxDocumentFilters } from '../../app/models/TaxDocument';
import { useHistory } from 'react-router-dom';
import TaxDocumentDataTable from './components/tables/TaxDocumentDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useTaxDocumentService from '../../app/services/hooks/useTaxDocumentService';
import { TaxDocumentStatus } from '../../app/models/TaxDocumentStatus';
import DebitNoteDataTable from './components/tables/TaxDocumentDataTable';
import TaxDocumentCreate from './components/TaxDocumentCreate';
import NoteCreate from './components/NoteCreate';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Documentos de Venta',
        url: '/tax-documents',
        isActive: true
    }
];

const TaxDocuments = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { fetchingGetTaxDocuments, getTaxDocuments, deleteTaxDocument, activeTaxDocument, createTaxDocument  } = useTaxDocumentService();
    const [taxDocuments, setTaxDocuments] = useState<TaxDocumentDataForm[]>([]);
    const [statuses, setStatuses] = useState<TaxDocumentStatus[]>([]);


    const [taxDocumentIdEdit, setTaxDocumentIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreateNote, setShowingCreateNote] = useState(false);
    const [showingCreateTaxDocument, setShowingCreateTaxDocument] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [noteType, setNoteType] = useState<string | null>(null); 
    const [documentType, setDocumentType] = useState<string | null>(null); 

    const [taxDocumentFilter, setTaxDocumentFilter] = useState<TaxDocumentFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [taxDocumentFilter]);

    const getAllTaxDocuments = () => {
        getTaxDocuments(taxDocumentFilter, {
            onSuccess: (response: ServiceResponse) => {
                setTaxDocuments(response.data.tax_documents);
                setStatuses(response.data.statuses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    // const showEdit = (taxDocumentId: number) => {
    //     setShowingEdit(true);
    //     setTaxDocumentIdEdit(taxDocumentId);
    // };

    const showEdit = (id: number) => {
        navigationPage(`/tax-documents/${id}/edit`);
    };

    const show = (id?: number) => {
        navigationPage(`/tax-documents/${id}/show`);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setTaxDocumentIdEdit(-1);
    };

    const showCreateTaxDocument = () => {
        setShowingCreateTaxDocument(true);
    };

    const hideCreateTaxDocument = () => {
        setShowingCreateTaxDocument(false);
    };

    const showCreateNote = (type: string, docType: string) => {
        setNoteType(type);
        setDocumentType(docType);
        setShowingCreateNote(true);
    };
    
    const hideCreateNote = () => {
        setNoteType(null);
        setDocumentType(null);
        setShowingCreateNote(false);
    };

    // const showCreateCreditNote = () => {
    //     setShowingCreateCreditNote(true);
    // };

    // const hideCreateCreditNote = () => {
    //     setShowingCreateCreditNote(false);
    // };

    const reloadTable = () => {
        getAllTaxDocuments();
    };

    const onSavedRedirect = (data: any) => {
        const id = data?.tax_document_id;
        if (id) {
            navigationPage(`/tax-documents/${id}/edit`);
        } else {
            toast.error("Error: No se pudo obtener el ID del documento.");
        }
    };

    const destroy = (taxDocumentId: number) => {
        const _text = 'Está a punto de eliminar el documento de venta #' + taxDocumentId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando documento de venta...');
                        deleteTaxDocument(taxDocumentId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El documento de venta no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Documentos de Venta" breadcrumbs={breadcrumbs} />

            <DefaultCard>

                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='row'>
                           <div className='col-auto'>
                       
                            <ButtonCreate
                                title="Nueva Factura"
                                callbackCreate={showCreateTaxDocument}
                            />
                        
                           </div>

                            <div className='col-auto'>
                            <ButtonCreate
                                title="Nueva Nota de Credito"
                                callbackCreate={() => showCreateNote("Crédito", "NOTA_CREDITO")}
                            />
                            </div>

                            <div className='col-auto'>
                            <ButtonCreate
                                title="Nueva Nota de Debito"
                                callbackCreate={() => showCreateNote("Débito", "NOTA_DEBITO")}
                            />
                            </div>
                           
                        </div>
                        
                    </div>
                <div className="col-12">
                    <TaxDocumentDataTable
                        taxDocuments={taxDocuments}
                        statuses={statuses}
                        edit={showEdit}
                        show={show}
                        destroy={destroy}
                        loading={fetchingGetTaxDocuments}
                        filter={taxDocumentFilter}
                        setFilter={setTaxDocumentFilter}
                        totalRows={totalRows}
                        callbackSuccess={reloadTable}
                    />
                </div>
            </div>
            </DefaultCard>

            {showingCreateTaxDocument ? (
                <DefaultModal
                    show={showingCreateTaxDocument}
                    handleClose={hideCreateTaxDocument}
                    title="Crear Factura"
                    backdrop={true}
                    showFooter={false}
                >
                    <TaxDocumentCreate
                        onSaved={(data) => {
                            reloadTable();
                            hideCreateTaxDocument();
                            onSavedRedirect(data);
                        }}
                        onCancel={hideCreateTaxDocument}
                        onError={hideCreateTaxDocument}
                    />
                </DefaultModal>
            ) : null}

            

            {showingCreateNote ? (
                <DefaultModal
                    show={showingCreateNote}
                    handleClose={hideCreateNote}
                    title={`Crear Nota de ${noteType}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <NoteCreate
                        documentType={documentType}
                        onSaved={(data) => {
                            reloadTable();
                            hideCreateNote();
                            onSavedRedirect(data);
                        }}
                        onCancel={hideCreateNote}
                        onError={hideCreateNote}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TaxDocuments;
