import { PurchaseorderDataForm, PurchaseOrderFilters } from '../../../../app/models/PurchaseOrder';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';

import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';

import React from 'react';
import PurchaseOrderFilter from './PurchaseOrderFilter';

interface Props {
    purchaseOrders: PurchaseorderDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: PurchaseOrderFilters;
    setFilter: (filter: PurchaseOrderFilters) => void;

    paginationRowsPerPageOptions?: any[];
}

const PurchaseOrderDataTable = ({
    purchaseOrders,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            width: 'auto',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">
                <div
                    dangerouslySetInnerHTML={{
                        __html: row.society ? String(row.society) : ''
                    }}
                />
            </span>
            )
        },

        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.provider ? String(row.provider) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">{row?.status}</span>
            )
        },
        {
            name: 'Valor Neto',
            selector: (row: any) => row?.gross_total_calculated,
            sortable: false,
            sortField: 'gross_total_calculated',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.gross_total_calculated??0, row?.currency, true)} {row?.currency}
                </span>
            )
        },
        {
            name: 'Impuestos',
            selector: (row: any) => row?.taxes_calculated,
            sortable: false,
            sortField: 'taxes_calculated',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.taxes_calculated??0, row?.currency, true)} {row?.currency}
                </span>
            )
        },

        {
            name: 'Valor Total',
            selector: (row: any) => row?.tax_total_calculated,
            sortable: false,
            sortField: 'tax_total_calculated',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">
                    {formatMoney(row.tax_total_calculated??0, row?.currency, true)} {row?.currency}
                </span>
            )
        },
        {
            name: 'creado',
            selector: (row: any) => row?.created_at,
            sortable: true,
            sortField: 'created_at',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.created_at)}</span>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: PurchaseorderDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <PurchaseOrderFilter filter={filter} setFilter={setFilter} />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={purchaseOrders}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default PurchaseOrderDataTable;
