import React from 'react';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatMoney } from '../../../../utils/utils';
import {
    ServiceManifestDataForm,
    ServiceManifestFilters
} from '../../../../app/models/ServiceManifest';
import ServiceManifestFilter from './ServiceManifestFilter';
import { Link } from 'react-router-dom';

interface Props {
    serviceManifests: ServiceManifestDataForm[];
    totalRows: number;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: ServiceManifestFilters;
    setFilter: (filter: ServiceManifestFilters) => void;

    paginationRowsPerPageOptions?: any[];
    columnDict: string[]
}

const ServiceManifestDataTable = ({
    serviceManifests,
    totalRows,
    loading = false,
    edit,
    destroy,
    show,
    filter,
    setFilter,
    columnDict
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            omit: !columnDict.includes('Id'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{row?.id}</span>
            )
        },
        {
            name: 'Sociedad',
            selector: (row: any) => row?.society,
            sortable: true,
            sortField: 'society',
            omit: !columnDict.includes('Sociedad'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.society ? String(row.society) : ''
                        }}
                    />
                </span>
            )
        },

        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            omit: !columnDict.includes('Proveedor'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.provider ? String(row.provider) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Orden de compra',
            selector: (row: any) => row?.purchase_order_id,
            sortable: true,
            sortField: 'purchase_order_id',
            omit: !columnDict.includes('Orden de compra'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <Link to={'/purchase-orders/' + row?.purchase_order_id + '/show'}>
                    <span className="text-primary">#{row?.purchase_order_id}</span>
                </Link>
            )
        },
        {
            name: 'Destino',
            selector: (row: any) => row?.destiny,
            sortable: true,
            sortField: 'destiny',
            omit: !columnDict.includes('Destino'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{row?.destiny}</span>
            )
        },
        {
            name: 'Fecha emisión factura',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            omit: !columnDict.includes('Fecha emisión factura'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name:'Fecha de entrega',
            selector: (row: any) => row?.delivery_date,
            sortable: true,
            sortField: 'delivery_date',
            omit: !columnDict.includes('Fecha de Entrega'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.delivery_date)}</span>
            )
        },
        {
            name: 'Estado',
            selector: (row: any) => row?.status,
            sortable: true,
            sortField: 'status',
            omit: !columnDict.includes('Estado'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{row?.status}</span>
            )
        },
        {
            name: 'Total Neto',
            selector: (row: any) => row?.gross_total_calculated,
            sortable: false,
            sortField: 'gross_total_calculated',
            omit: !columnDict.includes('Total Neto'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.gross_total_calculated ?? 0, row.currency)} {row.currency}</span>
            )
        },
        {
            name: 'Impuestos',
            selector: (row: any) => row?.taxes_calculated,
            sortable: false,
            sortField: 'taxes_calculated',
            omit: !columnDict.includes('Impuestos'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.taxes_calculated ?? 0, row.currency)} {row.currency}</span>
            )
        },
        {
            name: 'Valor Total',
            selector: (row: any) => row?.tax_total_calculated,
            sortable: false,
            sortField: 'tax_total_calculated',
            omit: !columnDict.includes('Valor Total'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{formatMoney(row.tax_total_calculated ?? 0, row.currency)} {row.currency}</span>
            )
        },
        // {
        //     name: 'creado',
        //     selector: (row: any) => row?.created_at,
        //     sortable: true,
        //     sortField: 'created_at',
        //     cell: (row: ServiceManifestDataForm, index: any, column: any) => (
        //         <span className="">{momentParseDate(row?.created_at)}</span>
        //     )
        // },
        {
            name: 'Contabilizada',
            selector: (row: any) => row?.all_accounted,
            sortable: true,
            sortField: 'all_accounted',
            omit: !columnDict.includes('Contabilizada'),
            
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <span className="">{row.all_accounted ? 'Si' : 'No'}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            
            omit: !columnDict.includes('Acciones'),
            cell: (row: ServiceManifestDataForm, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ServiceManifestFilter 
                        filter={filter} 
                        setFilter={setFilter} 
                        columnDict={columnDict}
                    />
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={serviceManifests}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default ServiceManifestDataTable;
