import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import {
    defaultDispatchProgramShowDataForm,
    DispatchProgramShowDataForm
} from '../../../app/models/DispatchProgram';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { RouterParams } from '../../../app/shared/interfaces';
import DispatchProgramShowForm from '../components/forms/DispatchProgramShowForm';
import DispatchProgramItemsContainer from '../components/DispatchProgramItemsContainer';
import { TransportationOrderStatusRequestDataForm } from '../../../app/models/TransportationOrder';
import { Interlocutor, InterlocutorFilters } from '../../../app/models/Interlocutor';
import { toast } from 'react-toastify';
import { Tab, Tabs } from 'react-bootstrap';
import InterlocutorsContainer from '../components/InterlocutorContainer';
import useInterlocutorService from '../../../app/services/hooks/useInterlocutorService';
import useDispatchProgramHistoryService from '../../../app/services/hooks/useDispatchProgramHistoryService';
import { DispatchProgramHistoryDataForm, DispatchProgramHistoryFilters } from '../../../app/models/DispatchProgramHistory';
import DispatchProgramHistoryContainer from '../components/DispatchProgramHistoryContainer';

const DispatchProgramShow = () => {
    const { id } = useParams<RouterParams>();

    const { 
        showDispatchProgram, 
        fetchingShowDispatchProgram, 
    } = useDispatchProgramService();

    const{
        getInterlocutorByDispatchProgram,
        fetchingGetInterlocutorsDispatchProgram
    } = useInterlocutorService()

    const [transportationOrder, setTransportationOrder] =
        useState<TransportationOrderStatusRequestDataForm>({
            id: 0,
            name: ''
        });
    const [transportationSubOrderItemId, setTransportationSubOrderItemId] = useState<number>(0);
    const [dispatchProgramShowDataForm, setDispatchProgramShowDataForm] =
        useState<DispatchProgramShowDataForm>({
            id: 0,
            contact_email: '',
            expired_at: '',
            client: '',
            quote_id: 0,
            quote: undefined,
            payment_method: '',
            payment_condition: '',
            currency: '',
            society: '',
            status: '',
            status_selected: undefined,
            status_choices: [],
            created_at: '',
            created_user: '',
            updated_at: '',
            last_modified_user: '',
            reference: '',
            days_count: 0
        });

    const [interlocutors, setInterlocutors] = useState<Interlocutor[]>([]);
    const [interlocutorsFilter, setInterlocutorsFilter] = useState<InterlocutorFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc'
        });

    const [totalRowsInterlocutors, setTotalRowsInterlocutors] = useState<number>(0);

    const {getAllDispatchProgramHistoriesByDispatchProgram, fetchingGetDispatchProgramHistoriesByDispatchProgram} = useDispatchProgramHistoryService();
    const [dispatchProgramHistory, setDispatchProgramHistory] = useState<DispatchProgramHistoryDataForm[]>([]);
    const [totalHistoryRows, setTotalHistoryRows] = useState<number>(0);
    const [filterHistory, setFilterHistory]= useState<DispatchProgramHistoryFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc'
        });

    useEffect(() => {
        reloadModule();
    }, []);

     useEffect(() => {
        reloadTableHistory();
    }, [filterHistory]);

    useEffect(() =>{
        reloadModuleInterlocutor();
    }, [interlocutorsFilter]);
   
    const reloadModule = () => {
        show(parseInt(id));
    };

    const reloadModuleInterlocutor = () => {
        getInterlocutorsByDispatch();
    };

    const getInterlocutorsByDispatch = () => {
    
            getInterlocutorByDispatchProgram(parseInt(id),interlocutorsFilter, {
                onSuccess: (response: ServiceResponse) => {
                    setInterlocutors(response.data.interlocutors);
                    setTotalRowsInterlocutors(response.data.total_rows)
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message, {
                        autoClose: 2000
                    });
                }
            });
        };

    const show = (id: number) => {
        showDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItemId(
                    response.data.dispatch_program.transportation_order_item_id
                );
                setTransportationOrder(response.data.transportation_order);
                setDispatchProgramShowDataForm(response.data.dispatch_program);
            }
        });
    };

    const reloadTableHistory = () => {
        getAllDispatchProgramHistoriesByDispatchProgram(Number(id),filterHistory, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchProgramHistory(response.data.dispatch_program_histories);
                setTotalHistoryRows(response.data.total_rows);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message, {
                        autoClose: 2500
                    });
                }
            
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Despachos',
            url: '/dispatch-programs',
            isActive: false
        },
        {
            name: 'Ver despacho #' + id,
            url: '/dispatch-programs/' + id + '/show',
            isActive: true
        }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Mostrar despacho #${id}`} breadcrumbs={breadcrumbs} />
            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                            <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                            {!fetchingShowDispatchProgram ? (
                                <>
                                    <DispatchProgramShowForm
                                        dispatchProgramShowDataForm={dispatchProgramShowDataForm}
                                        transportationOrder={transportationOrder}
                                    />
                                </>
                            ) : (
                                <LazyLoading />
                            )}
                        </DefaultCard>

                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                                {!fetchingShowDispatchProgram ? (
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={dispatchProgramShowDataForm.created_user}
                                        createdAt={dispatchProgramShowDataForm.created_at}
                                        updatedBy={dispatchProgramShowDataForm.last_modified_user}
                                        updatedAt={dispatchProgramShowDataForm.updated_at}
                                        status={
                                            dispatchProgramShowDataForm.status_selected
                                                ? dispatchProgramShowDataForm.status_selected.name.toString()
                                                : ''
                                        }
                                    />
                                ) : (
                                    <LazyLoading />
                                )}
                            </div>
                        </div>
                        </div>
                    </div>

                </Tab>
                <Tab eventKey="interlocutors" title="Interlocutores">

                        <InterlocutorsContainer
                            dispatchProgramId={parseInt(id)}
                            interlocutors={interlocutors}
                            totalRows={totalRowsInterlocutors}
                            reloadModuleInterlocutor={reloadModuleInterlocutor}
                            filter={interlocutorsFilter}
                            setFilter={setInterlocutorsFilter}
                            isShow={true}
                            loadingInterlocutors={fetchingGetInterlocutorsDispatchProgram}

                        />
                    </Tab>

                    <Tab eventKey="history" title="Historial">
                    <div className="col-12">
                            <DispatchProgramHistoryContainer
                                dispatchProgramId={parseInt(id)}
                                dispatchProgramHistory={dispatchProgramHistory}
                                totalRowsHistory={totalHistoryRows}
                                filterHistory={filterHistory}
                                setFilterHistory={setFilterHistory}
                                setDispatchProgramHistoryTable={setDispatchProgramHistory}
                                reloadDispatchProgramHistoryTable={reloadTableHistory}
                                loading={fetchingGetDispatchProgramHistoriesByDispatchProgram}
                            />

                    </div>
                    </Tab>

            </Tabs>
            
            {!fetchingShowDispatchProgram ? (
                <>
                    <DispatchProgramItemsContainer
                        dispatchProgramId={parseInt(id)}
                        transportationSubOrderItemId={transportationSubOrderItemId}
                        canBeEdited={false}
                        isShow={true}
                        status=''
                        reloadModuleInterlocutor={reloadModuleInterlocutor}
                    />
                </>
            ) : null}
        </>
    );
};

export default DispatchProgramShow;
