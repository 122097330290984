import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCard from '../../../components/default/DefaultCard';
import { AppContext } from '../../../contexts/AppContext';
import { DispatchProgramHistoryDataForm, DispatchProgramHistoryFilters } from '../../../app/models/DispatchProgramHistory';
import DispatchProgramHistoryDataTable from './tables/DispatchProgramHistoryDataTable';
import useDispatchProgramHistoryService from '../../../app/services/hooks/useDispatchProgramHistoryService';

interface Props {
    dispatchProgramId: number;
    dispatchProgramHistory: DispatchProgramHistoryDataForm[]
    totalRowsHistory: number;
    filterHistory: DispatchProgramHistoryFilters;
    setFilterHistory: (filter: DispatchProgramHistoryFilters) => void;
    setDispatchProgramHistoryTable?: (dispatchProgramHistory: DispatchProgramHistoryDataForm[]) => void;
    reloadDispatchProgramHistoryTable: () => void;
    loading: boolean;
    readOnly?: boolean;
}

const DispatchProgramHistoryContainer = ({ 
    dispatchProgramId,
    dispatchProgramHistory,
    totalRowsHistory,
    filterHistory,
    setFilterHistory,
    setDispatchProgramHistoryTable,
    loading,
    reloadDispatchProgramHistoryTable,
    

}: Props) => {

   
    return (
        <>
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <DispatchProgramHistoryDataTable
                            dispatchProgramId={dispatchProgramId}
                            dispatchProgramHistories={dispatchProgramHistory}
                            loading={loading}
                            totalRows={totalRowsHistory}
                            filter={filterHistory}
                            setFilter={setFilterHistory}
                            callbackSuccess={reloadDispatchProgramHistoryTable}
                        />
                    </div>
                </div>
            </DefaultCard>
        </>
    );
};

export default DispatchProgramHistoryContainer;
